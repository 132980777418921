exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-capital-js": () => import("./../../../src/pages/capital.js" /* webpackChunkName: "component---src-pages-capital-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cumplimiento-js": () => import("./../../../src/pages/cumplimiento.js" /* webpackChunkName: "component---src-pages-cumplimiento-js" */),
  "component---src-pages-directiva-js": () => import("./../../../src/pages/directiva.js" /* webpackChunkName: "component---src-pages-directiva-js" */),
  "component---src-pages-educacion-js": () => import("./../../../src/pages/educacion.js" /* webpackChunkName: "component---src-pages-educacion-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medios-js": () => import("./../../../src/pages/medios.js" /* webpackChunkName: "component---src-pages-medios-js" */),
  "component---src-pages-responsabilidad-js": () => import("./../../../src/pages/responsabilidad.js" /* webpackChunkName: "component---src-pages-responsabilidad-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-sugerencias-js": () => import("./../../../src/pages/sugerencias.js" /* webpackChunkName: "component---src-pages-sugerencias-js" */),
  "component---src-templates-articulo-js": () => import("./../../../src/templates/articulo.js" /* webpackChunkName: "component---src-templates-articulo-js" */)
}

