module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Maximiza","short_name":"Maximiza","start_url":"/","background_color":"#ffffff","theme_color":"#00735f","display":"standalone","orientation":"portrait","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"69bbc78e4bf62c085d643934462e1589"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/empresa/","/servicios/","/directiva/","/contacto/","/capital/","/responsabilidad/","/medios/","/blog/*","/cumplimiento/","/sugerencias/"],"workboxConfig":{"globPatterns":["src/assets/images/icon.png"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-4W17JXKS6P","head":true,"pageTransitionDelay":0,"defer":false,"cookieDomain":"maximiza.com.ve","enableWebVitalsTracking":true,"anonymize":false,"respectDNT":false,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
